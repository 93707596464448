import { useEffect, useState } from 'react'
import { Img, api, Button, ModalMini } from './../Run'
import { useParams, Link } from 'react-router-dom'

import Bar from './BarItem'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faRubleSign } from '@fortawesome/free-solid-svg-icons'

let Index = ({root,products,setProducts}) => {

    let {id} = useParams()

    let [modal,setModal] = useState({})
    let [shop,setShop] = useState(null)
    let [button,setButtom] = useState({products:false})

    useEffect(() => {

        api('POST', '/shop', {index:'item',id:id}).then(response => {
            
            setShop(response)

        }).then(() => {
            
            setShop(shop => ({...shop, sections: shop.sections.map(e => ({...e, positionTop: document.getElementById('item'+e.id).offsetTop - 70, positionBottom: document.getElementById('item'+e.id).offsetTop + document.getElementById('item'+e.id).offsetHeight - 70}))}))
        
        })

    },[])

    let add = (section,product) => {

        setButtom(button => ({...button,products:true}))
        setShop({...shop, products:shop.products.map(e => e.id === section ? {...e, products:e.products.map(ee => ee.id === product.id ? {...ee, buttonAdd:true} : ee )} : e)})

        api('POST', '/cart', {index:'add',id:product.id}).then(response => {

            if (response.status) {

                setProducts(products.find(p => p.id === product.id) ? products.map(e => e.id === product.id ? {...e, amount: response.amount } : e) : [...products, {name:product.name,id:product.id,price:product.price, amount: response.amount}])

            } else {

                if (response.code === 201) {
                    setModal(modal => ({...modal,city:true,name: response.name}))
                } else if (response.code === 202) {
                    setModal(modal => ({...modal,address:true,name: response.name}))
                } else if (response.code === 203) {
                    setModal(modal => ({...modal,products:true,name: response.name}))
                }

            }
            
            setButtom(button => ({...button,products:false}))
            setShop({...shop, products:shop.products.map(e => e.id === section ? {...e, products:e.products.map(ee => ee.id === product ? {...ee, buttonAdd:false} : ee )} : e)})

        })

    }

    let remove = (section,product) => {

        setButtom(button => ({...button,products:true}))
        setShop({...shop, products:shop.products.map(e => e.id === section ? {...e, products:e.products.map(ee => ee.id === product.id ? {...ee, buttonRemove:true} : ee )} : e)})

        api('POST', '/cart', {index:'remove',id:product.id}).then(response => {

            if (response.status) {

                setProducts(products.find(p => p.id === product.id) ? products.map(e => e.id === product.id ? {...e, amount: response.amount } : e) : [...products, {name:product.name,id:product.id,price:product.price, amount: response.amount}])

            }

            setButtom(button => ({...button,products:false}))
            setShop({...shop, products:shop.products.map(e => e.id === section ? {...e, products:e.products.map(ee => ee.id === product ? {...ee, buttonRemove:false} : ee )} : e)})

        })

    }

    let city = () => {
        setModal({})
        window.location.hash = '#point'
    }

    let address = () => {
        setModal({})
        window.location.hash = '#address'
    }

    let clear = () => {
        setModal({})
    }


    if (shop) {
        return <div className='shop-container'>
            <Bar list={shop.sections} />

            <div className='container'>
                <div className='panel p-3'>
                    <h2 className='title'>{shop.shop.name}</h2>
                </div>
            </div>
            
            <div className='container'>
                <div className='section-list'>
                    {shop.products?.map((e,key) => <div key={key} id={"item"+e.id}><div className='section px-3 py-2 mb-3'>
                        {e.name}
                    </div>
                    
                    <div className='row product-row'>
                        
                        {e.products.map((ee,ekey) => <div key={ekey} className='product-box col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mb-4'>
                            
                            <div><Img className={false ? 'img-bg close' : 'img-bg'} src={'//files.tin.bar/'} /></div>
                            <div className='title'>{ee.name}</div>
                            <div>
                                
                                <button onClick={() => add(e.id,ee)} className='btn-buy' disabled={button.products}>
                                    <Button title={<><FontAwesomeIcon icon={faPlus} /> 300 <FontAwesomeIcon icon={faRubleSign} /></>} color='btn-load-black' param={ee.buttonAdd}/>
                                </button>

                                {products.find(p => p.id === ee.id && p.amount !== 0) && <>
                                    {products.find(p => p.id === ee.id).amount}
                                    <button key={key} onClick={() => remove(e.id,ee)} className='btn-buy' disabled={button.products} ><Button title={<FontAwesomeIcon icon={faMinus} disabled />} color='btn-load-black' param={ee.buttonRemove}/></button>
                                </>}

                            </div>
                        </div>)}
                    
                    </div>
                    
                    </div>)}    

                </div>
            </div>
            
            {modal.city && <ModalMini close={()=>setModal(modal => ({...modal,city:false,name: ''}))}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 modal-mini-container' onMouseDown={e => {e.stopPropagation()}}>
                            
                            <div className='my-3'>В городе <b>{root.point.name}</b> нет доставки данного завидения!</div>

                            <div className='my-3'>
                                <div className='mb-1'><button onClick={city} className='btn btn-primary'>Изменить город</button></div>
                                <Link to='/' className='btn btn-dark'>Посмотреть, что доступно</Link>
                            </div>
            
                        </div>
                    </div>
                </div>
            </ModalMini>}

            {modal.address && <ModalMini close={()=>setModal(modal => ({...modal,address:false,name: ''}))}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 modal-mini-container' onMouseDown={e => {e.stopPropagation()}}>
                            
                            <div className='my-3'>Не доставляет по указанному адресу!</div>

                            <div className='my-3'>
                                <div className='mb-1'><button onClick={address} className='btn btn-primary'>Изменить адрес</button></div>
                                <Link to='/' className='btn btn-dark'>Посмотреть, что доступно</Link>
                            </div>
            
                        </div>
                    </div>
                </div>
            </ModalMini>}

            {modal.products && <ModalMini close={()=>setModal(modal => ({...modal,products:false,name: ''}))}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 modal-mini-container' onMouseDown={e => {e.stopPropagation()}}>
                            
                            <div className='my-3'>Все ранее добавленные товары из <b>{modal.name}</b> будут удалены из корзины!</div>

                            <div className='my-3'>
                                <div className='mb-1'><button onClick={clear} className='btn btn-primary'>Продолжить</button></div>
                                <button onClick={()=>setModal({})} className='btn btn-dark'>Отмена</button>
                            </div>
            
                        </div>
                    </div>
                </div>
            </ModalMini>}

        </div> 
    } else {
        return 'load'
    }
    
}

export default Index