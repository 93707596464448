import React, {useEffect,useState} from 'react'
import axios from 'axios'

import fon from './svg/fon.svg'

const apiUrl = '//dev.tin.bar'

let api = async (method,url,data,reToken) => {

    let token = localStorage.getItem('token') ? localStorage.getItem('token') : false
    let setToken = reToken ? {token:token,reToken:reToken} : {token:token}

    switch (method) {
        case 'POST': return axios(apiUrl+url, {
            method: "post",
            data: {data:data, ...setToken},
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true
          }).then(response => { 
            if (response.data.status) {
                return response.data
            } else {
                return apiError(response.data,method,url,data)
            }
        }).catch(error => {
            setTimeout(()=>api(method,url,data),10000)
        })

        default: return axios.get(apiUrl+url, {params: data}).then(response => {
            if (response.data.status) {
                return response.data
            } else {
                return apiError(response.data,method,url,data)
            }
          }).catch(error => {
            setTimeout(()=>api(method,url,data),10000)
          })
    }

}

let apiError = (response,method,url,data) => {

    switch (response.code) {
        case 300:
            localStorage.clear()
            localStorage.setItem('token',response.token)
            return api(method,url,data)
        case 301:
            let reToken = localStorage.getItem('reToken') ? localStorage.getItem('reToken') : false
            return api(method,url,data,reToken)
        case 302:
            localStorage.setItem('token',response.token)
            localStorage.setItem('reToken',response.reToken)
            return api(method,url,data)
        default: return response
    }
    
}

let Modal = ({children,close,className}) => {

    return <div onMouseDown={close} className='modal'>
        <div className='modal-center-main'>
            <div className={className ? className : 'modal-center-box'}>{children}</div>
        </div>
    </div>

}

let ModalMini = ({children,close}) => {

    return <div onMouseDown={close} className='modal-mini'>
        <div className='modal-mini-center-main'>
            <div className='modal-mini-center-box'>{children}</div>
        </div>
    </div>

}

let routerModal = (hash,setModal) => {

    let hashs = window.location.hash.split('#').filter(element => element !== '')

    if (hash) {
        hashs.push(hash)
    } else {
        hashs.splice(-1,1)
    }

    let url = window.location.href.replace(/#.*$/, '')
    window.history.pushState('', document.title, url + (hashs.length === 0 ? '' : '#' +hashs.join('#')))
    setModal(hashs.pop())

}

let Load = ({className}) => {

    return <div className={className ? className : 'load-box'}>
        <div className='load-center'>
            <div className='spinner'></div>
        </div>
    </div>

}

let Button = (data) => {

    return data.param ? <span className={data.color}></span> : data.title
    
}

let Img = ({className,src}) => {
    let [img, setImg] = useState(false)
    useEffect(() => {
       setImg(false)
       let i = new Image()
       i.src = src
       i.onload = () => {
          setImg(true)
       }
    },[src])
    
    if (img) {
       return <div className={className} style={{backgroundImage:'url("'+src+'")',backgroundSize:'cover',backgroundPosition:'50% 50%'}}/>
    } else {
       return <div className={className} style={{backgroundImage:'url("'+fon+'")',backgroundSize:'cover',backgroundPosition:'50% 50%'}} />
    }
 }

 let Placeholder = (d) => {

    return d.data && <div className='placeholder-box'>
        <div className='placeholder-null'>
            {d.data}
        </div>
    </div>

 }

let addressString = (e) => {

    let address = (e.settlement ? e.settlement+ ' ':'')+(e.street ? e.street+' ':'')+(e.stead ? 'участок '+e.stead+' ':'')+(e.house ? 'д. '+e.house+' ':'')+(e.flat ? 'кв. '+e.flat+' ':'')+(e.entrance ? 'под. '+e.entrance:'')

    return address ? address : false
}

export {Modal,ModalMini,routerModal,Load,api,Button,Img,Placeholder,addressString}