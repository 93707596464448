import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'

import { api, routerModal, Button } from './Run'

import './style.css'
import './tinway.css'
import './app.css'

import { BrowserRouter,Routes,Route } from 'react-router-dom'
import Header from './Header/Index'
import Footer from './Footer/Index'
import { Point,Address } from './Address/General'

import Cart from './Cart/Index'
import Sber from './Cart/Sber'

import App from './App'
import Settings from './Settings/Index'
import ShopId from './Shop/ShopId'

import NoPage from './NoPage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotate, faBars, faRubleSign } from '@fortawesome/free-solid-svg-icons'

let Index = () => {

  let def = {root:false,tel:null,point:false}

  let [modal,setModal] = useState(null)

  let [root,setRoot] = useState(def)
  let [products,setProducts] = useState([])
  let [load,setLoad] = useState({root: false})

  let storage = (e) => {

    switch (e.key) {

      case 'root':

        setRoot(JSON.parse(e.newValue))

        break

      case 'cart':

        setProducts(JSON.parse(e.newValue))

        break

      default: console.log('hi! :)')

    }

  }

  let start = () => {

    api('POST', '/root').then(response => {

      setRoot({root:response.root,tel:response.tel, point:response.point})
      setProducts(response.products)
      setLoad(load => ({...load, root:true}))

    })

  }

  useEffect(() => {

    let hashs = window.location.hash.split('#').filter(element => element !== '')

    setModal(hashs.pop())

    window.addEventListener('hashchange', () => {

      let hashs = window.location.hash.split('#').filter(element => element !== '')

      setModal(hashs.pop())

    })

    window.addEventListener('storage', storage, false);
    start()

  },[])
  
  useEffect(() => document.body.style.overflowY = ['point','address','cart','sber'].includes(modal) ? 'hidden' : 'auto',[modal])

  useEffect(()=>{

    localStorage.setItem('root', JSON.stringify(root))

  },[root])

  useEffect(()=>{

    localStorage.setItem('products', JSON.stringify(products))

  },[products])

  return <BrowserRouter>
  {load.root ? <div><Header root={root} setRoot={setRoot} setModal={setModal} load={load} setLoad={e => setLoad(load=>({...load,point:e}))} />
  <Routes>
    <Route path='/' element={<App root={root} setRoot={setRoot} />} />
    <Route path='/settings' element={<Settings root={root} setRoot={setRoot} />} />
    <Route path='/shop@:id' element={<ShopId root={root} products={products} setProducts={setProducts} />} />
    <Route path='*' element={<NoPage />}></Route>
  </Routes> </div>: <div className='load-page'><div className='load-center'><div className='spinner'></div></div></div>}
  <Footer />
  <div className='cart-footter'>
    <div className='container'>
    <div className='cart-btn'>
      <button to='/settings' className='btn btn-dark'><FontAwesomeIcon icon={faRotate} /></button>&nbsp;

      <button onClick={()=>routerModal('cart',setModal)} className='btn btn-primary btn-block position-relative' disabled={load.cart}>
        <Button title='Корзина' color='btn-white' param={load.cart}/>
      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
        700 <FontAwesomeIcon icon={faRubleSign} />
      </span>
      </button>&nbsp;

    <button to='/settings' className='btn btn-dark'><FontAwesomeIcon icon={faBars} /></button>
    
    </div>
    </div>
  </div>
  
  {modal === 'point' && <Point close={hash => routerModal(hash,setModal)} setRoot={e => setRoot(root => ({...root,point:e}))} load={load} setLoad={e => setLoad(load=>({...load,point:e}))} />}
  {modal === 'address' && <Address close={hash => routerModal(hash,setModal)} root={root} setRoot={e => setRoot(root => ({...root,point:e}))} load={load} setLoad={e => setLoad(load=>({...load,point:e}))} setModal={setModal} />}
  {modal === 'cart' && <Cart close={hash => routerModal(hash,setModal)} root={root} setRoot={setRoot} setModal={setModal} load={load} products={products} setProducts={setProducts} />}
  {modal === 'sber' && <Sber close={hash => routerModal(hash,setModal)} />}


  </BrowserRouter>
  
}

ReactDOM.render(<React.StrictMode><Index /></React.StrictMode>,document.getElementById('root'))
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
