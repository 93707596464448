import { useEffect, useState } from 'react'
import { api,Button } from './../Run'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'

let Index = ({root,setRoot}) => {

    let [index,setIndex] = useState({view: root.root ? 'root' : localStorage.getItem('tel') ? 'key' : 'tel'})
    let [input,setInput] = useState({tel:localStorage.getItem('tel') ? localStorage.getItem('tel') : '',key:''})
    let [button,setButton] = useState({next: false,confirm: false})
    let [errors,setErrors] = useState({})

    useEffect(() => {

        setIndex({view: root.root ? 'root' : localStorage.getItem('tel') ? 'key' : 'tel'})

    },[root.tel])

    let next = event => {

        setButton(button => ({...button,next:true}))

        api('POST', '/auth', {index:'next',...input}).then(response => {

            if (response.status) {

                setIndex(index => ({...index, view:'key'}))
                localStorage.setItem('tel',input.tel)
                
            } else {

                setErrors(response.errors)

            }

            setButton(button => ({...button,next:false}))

        })

        event.preventDefault()

    }

    let confirm = event => {
        setButton(button => ({...button,confirm:true}))
        api('POST', '/auth', {index:'confirm',...input}).then(response => {
            if (response.status) {
                localStorage.removeItem('tel')
                localStorage.setItem('token',response.token)
                localStorage.setItem('reToken',response.reToken)
                setRoot(root => ({...root,root:true,tel:response.tel}))
                setIndex(index => ({...index, view:'root'}))
                setInput(input => ({...input, tel: '', key: ''}))
            } else {
                setErrors(response.errors)
                if (response.errors.tel) {
                    setIndex(index => ({...index, view:'tel'}))
                    setInput(input => ({...input, key: ''}))
                    setErrors(error => ({...error,key:false}))
                }
            }
            setButton(button => ({...button,confirm:false}))
        })
        event.preventDefault()
    }

    let change = event => {
        setInput(input => ({...input, [event.target.name]: event.target.value}))
        event.persist()
    }

    let edit = () => {
        setIndex(index => ({...index, view:'tel'}))
        setInput(input => ({...input, key: ''}))
        setErrors(error => ({...error,key:false}))
        localStorage.removeItem('tel')
    }

    if (index.view === 'tel') {
        return <div>
            <form>
                <div className='input-group mb-3'>
                    <span className='input-group-text'>+7</span>
                    <input onChange={change} name='tel' value={input.tel} type='text' className={`form-control ${errors.tel && 'is-invalid'}`} placeholder='Телефон' autoComplete='off' />
                </div>
                <button onClick={next} className='btn btn-primary' disabled={button.next}><Button title='Далее' color='btn-white' param={button.next}/></button>
            </form>
        </div>
    } else if (index.view === 'key') {
        return <div>
            <form>
                Код отправлен по смс - {input.tel}
                <div className='mb-3'>
                    <input onChange={change} name='key' value={input.key} type='text' className={`form-control ${errors.key && 'is-invalid'}`} placeholder='Ключ' autoComplete='off' />
                </div>
                <button onClick={edit} className='btn btn-dark'><FontAwesomeIcon icon={faPencil} /></button>&ensp;
                <button onClick={confirm} className='btn btn-primary' disabled={button.confirm}><Button title='Подтвердить' color='btn-white' param={button.confirm} /></button>
            </form>
        </div>
    } else {
        return root.tel
    }
}

export default Index