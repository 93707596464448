import { Link } from 'react-router-dom'
import './style.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

let Index = () => {

    return <div className='footer'>
        <div className='container py-2 text-end'>
            <Link to='/partner' className='btn btn-dark'><FontAwesomeIcon icon={faInfoCircle} /></Link>
        </div>
    </div>
    
}

export default Index