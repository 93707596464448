import { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from "swiper";

import 'swiper/css'
import './style.css'

let Index = ({list}) => {
    
    let [scroll,setScroll] = useState({scroll: 0, bar: 55})
    let [slide, setSlide] = useState(null)

    let [swiperRef, setSwiperRef] = useState(null)

    let isActiveScroll = useRef(true)
    let scrollTimeout = useRef(null)
    let isActiveSlide = useRef(true)

    useEffect(() => {
  
        window.addEventListener('scroll', function() {

            clearTimeout(scrollTimeout.current)

            if (isActiveScroll.current) {

                setScroll(scroll => ({...scroll, scroll:window.scrollY}))

                scrollTimeout.current = setTimeout(() => isActiveSlide.current = true, 150)

            }

    
        })

        return () => isActiveScroll.current = false
    
    },[])


    useEffect(() => {

        let activeSlide = list.findIndex((e,i) => scroll.scroll >= e.positionTop && scroll.scroll <= e.positionBottom)

        if (activeSlide >= 0 && isActiveSlide.current) {
            setSlide(activeSlide)
            swiperRef.slideTo(activeSlide , 1000)
        }

    },[scroll])

    let scrollSections = (key) => {
        
        isActiveSlide.current = false

        setSlide(key)
        swiperRef.slideTo(key , 1000)

        let scr = (a,b,i,sl) => {

            i++; if (i > 30) {
                return
            }

            let step = a + ((b - a) + 5) / 30 * i
            window.scroll(0,step)

            setTimeout(() => scr(a,b,i,sl), 1)

        }

        scr(scroll.scroll,list[key].positionTop, 0, key)

    }

    
    return <div className='bar-sections user-select-none' style={{overflow:'hidden',top:scroll.scroll >= 55 && '0px', position:scroll.scroll >= 55 && 'fixed'}}>
        <div className='container bar'>
            
            <Swiper onSwiper={setSwiperRef} initialSlide={0} slidesPerView={'auto'} navigation={true} modules={[Navigation]}  style={{overflow:'visible'}}>
                
                {list.map((e,key) => <SwiperSlide key={key} onClick={() => scrollSections(key)} className={'bar-item ' + (slide === key && 'bar-item-active')}>{e.name}</SwiperSlide>)}
            
            </Swiper>
        
        </div>
    </div>

}

export default Index