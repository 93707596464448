import React, { useEffect, useState } from 'react'
import { api } from './Run'

import Shop from './Shop/Index'

let App = ({root}) => {

  let [load,setLoad] = useState({})
  let [shop,setShop] = useState({open:[],close:[]})
  
  useEffect(() => {

    setLoad(load => ({...load, shop: false}))
    
    api('POST', '/shop', {index:'list'}).then(response => {
      setShop(shop => ({...shop, ...response.list}))
      setLoad(load => ({...load, shop: true}))
    })
  
  },[root.point])

  return<>

  <Shop load={load.shop} shop={shop} />

  </> 

}

export default App;
