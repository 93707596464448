import { useState, useRef, useEffect } from 'react'
import { routerModal, addressString, Button, api } from './../Run'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'

let Index = ({setModal,load,setLoad,data}) => {
    
    let [input,setInput] = useState(data)

    let addressRef = useRef()
    let isActive = useRef(true)

    useEffect(()=> {

        return () => isActive.current = false

    },[])

    let checkbox = (e) => {

        setLoad(load => ({...load, button:true,address:true}))
        
        setInput(input => ({...input, [e.target.name]:e.target.checked}))

        if (isActive.current) {
            
            api('POST', '/address', {index:'editAddress',...input, [e.target.name]:e.target.checked}).then(response => {
                
                if (response.status) {
                    
                    setInput(input => ({...input, ...response.point}))
            
                }
            
                setLoad(load => ({...load, button:false,address:false}))
        
            })

        }

        e.persist()

    }

    let change = (e) => {
        
        clearTimeout(addressRef.current)

        setLoad(load => ({...load, button:true,address:true}))

        setInput(input => ({...input, [e.target.name]:e.target.value}))
        

        addressRef.current = setTimeout(() => {
            
            if (isActive.current) {

                api('POST', '/address', {index:'editAddress',...input, [e.target.name]:e.target.value}).then(response => {
                    
                    if (response.status) {
                        
                        setInput(input => ({...input, ...response.point}))
                    }
                
                    setLoad(load => ({...load, button:false,address:false}))
                })
                
            }

        }, 1000)

        e.persist()

    }


    return <div>
        
        <div className='d-grid gap-2'>
            
            <button onClick={()=>routerModal('address',setModal)} className='btn btn-dark box-address-edit mb-3' disabled={load.address}>
                <Button title={<div className='row'>
                    <div className='col-auto box-address-icon'><FontAwesomeIcon icon={faPencil} /></div>
                    <div className='col'>{addressString(input) ? addressString(input) : 'Укажите адрес!'}</div>
                    </div>} color='btn-white' param={load.address} />
                </button>
            
        </div>
        
        <div className='form-check form-switch mb-3'>
            
            <input onChange={checkbox} name='privatehouse' disabled={load.address} className='form-check-input' type='checkbox' id='house' checked={input.privatehouse ? input.privatehouse : false} />
            <label className='form-check-label' htmlFor='house'>Частный дом</label>
        
        </div>
        
        {!input.privatehouse && <div className='row g-2 mb-3'>
            
            <div className='col'>
                <div className='form-floating'>
                    <input onChange={change} name='flat' value={input.flat ? input.flat : ''} className={'form-control '+(!input.flat && 'is-invalid')} type='text' placeholder='Квартира' autoComplete='off'/>
                    <label>Квартира</label>
                </div>
            </div>
            
            <div className='col'>
                <div className='form-floating'>
                    <input onChange={change} name='entrance' value={input.entrance ? input.entrance : ''} className={'form-control '+(!input.entrance && 'is-invalid')} type='text' placeholder='Подьезд' autoComplete='off'/>
                    <label>Подьезд</label>
                </div>
            </div>
        </div>}
    
    </div>

}

export default Index