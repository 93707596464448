
import { api, Modal, Load } from './../Run'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'

let Index = ({close}) => {

    let iframe = useRef()
    let [load,setLoad] = useState(true)

    useEffect(()=> {


        api('POST', '/cart', {index:'sber'}).then(response => {

            if (response.status) {
                iframe.current.src = 'https://3dsec.sberbank.ru/payment/merchants/sbersafe_sberid/payment_ru.html?mdOrder='+response.orderId
            
                iframe.current.onload = function() {
                    setLoad(false)
                }
            }
            
        })

    },[])

    return <Modal close={()=>close(null)} className='modal-no-box'>
        <div onMouseDown={e => {e.stopPropagation()}}>
            
            <div className='modal-sber-header'>
                <div className='container'>
                    
                    <button onClick={()=>close(null,true)} className='btn btn-dark'><FontAwesomeIcon icon={faXmark} /></button>
                
                </div>
            </div>

            {load && <Load className='load-box-fullscreen' /> }
            <iframe ref={iframe} className='iframe-sber' />
        </div>
    </Modal>
    
}

export default Index