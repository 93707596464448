import { useEffect, useRef, useState } from 'react'
import { Modal,Load,api,Button, Placeholder,routerModal } from './../Run'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark,faCity,faPencil,faMagnifyingGlass, faClipboardList } from '@fortawesome/free-solid-svg-icons'

let ViewAddress = ({type,open,root,load}) => {

    switch (type) {
        case 'point':
            return <button onClick={()=>open('point')} className='btn btn-dark' disabled={load.point}><Button title={root.point.point ? <><FontAwesomeIcon icon={faCity} /> {root.point.point}</> : <><FontAwesomeIcon icon={faPencil} /> Выбрать город</>} color='btn-white' param={load.point}/></button> 
        default: return <button onClick={()=>open('point')} className='btn btn-secondary mx-2'>Каменка 2</button> 
    }

}

let Point = ({close,setRoot,setLoad}) => {

    let [list,setList] = useState([])

    useEffect(() => {
        
        api('POST', '/address', {index:'point'}).then(response => {

            if (response.status) {
                
                setList(response.list)

            } else {

            }
        
        })

    },[])

    let click = e => {

        setLoad(true)
        close(null)

        api('POST', '/address', {index:'editPoint',point:e.id}).then(response => {

            if (response.status) {

                setRoot(response.point)

            } else {

                setRoot(false)

            }

            setLoad(false)

        })

    }

    return <Modal close={()=>close(null)}>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 modal-container' onMouseDown={e => {e.stopPropagation()}}>

                    <div className='modal-header'>
                        <button onClick={()=>close(null)} className='btn btn-dark'><FontAwesomeIcon icon={faXmark} /></button>
                    </div>

                    {list.length ? list.map((e,key)=><div onClick={()=>click(e)} key={key} className='py-2 list-box'>
                        {e.name}
                        <div>{e.region}</div>
                    </div>) : <Load />}

                </div>
            </div>
        </div>
    </Modal>

}

let Address = ({close,root,setRoot,setModal,load,setLoad}) => {

    let [loadPage,setLoadPage] = useState({address:false})

    let [input,setInput] = useState({address:''})
    let [list,setList] = useState([])

    let isActive = useRef(true)

    let address = (e) => {

        setLoadPage(loadPage => ({...loadPage,address:true}))
        
        api('POST', '/address', {index:'addressList',address:e}).then(response => {
            
            if (response.status) {
                
                setList(response.list)
            
            }
            
            setLoadPage(loadPage => ({...loadPage,address:false}))
        
        })

    }

    useEffect(() => {

        if (isActive.current) {
            address(input.address)
        }
    
    },[input.address])

    let change = (e) => {

        setInput(input => ({...input, [e.target.name]: e.target.value}))

        e.persist()

    }

    let click = (e) => {

        setLoadPage(loadPage => ({...loadPage,address:true}))

        api('POST', '/address', {index:'address',...e}).then(response => {

            setInput(input => ({...input, address: (e.settlement ? e.settlement+ ' ':'')+(e.street ? e.street+' ':'')+(e.stead ? 'участок '+e.stead+' ':'')+(e.house ? 'д. '+e.house+' ':'')+(e.flat ? 'кв. '+e.flat+' ':'')+(e.entrance ? 'под. '+e.entrance:'')}))

            if (response.status) {

                isActive.current = false
                setRoot(response.point)
                close(null)

            } else {

            }

        })

    }
    
    return <Modal close={()=>close(null)}>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 modal-container' onMouseDown={e => {e.stopPropagation()}}>

                <div className='modal-header mb-3'>
                    <button onClick={()=>close(null)} className='btn btn-dark'><FontAwesomeIcon icon={faXmark} /></button>&ensp;
                    <button onClick={()=>routerModal('point',setModal)} className='btn btn-primary' disabled={load.point}><Button title={root.point.point ? <><FontAwesomeIcon icon={faPencil} /> {root.point.point}</> : <><FontAwesomeIcon icon={faPencil} /> Выбрать город</>} color='btn-white' param={load.point}/></button>
                </div>
                
                <div className='input-group flex-nowrap'>
                    <span className='input-group-text' ><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                    <input type='text' name='address' value={input.address} onChange={change} className='form-control' placeholder='Адрес' autoComplete='off' />
                </div>
            

                {loadPage.address ? <Load /> : list.length ? list.map((e,key)=><div key={key} onClick={()=>click(e)} className='py-2 list-box'>

                    {e.settlement && <span className='fw-bold'>{e.settlement} </span>}
                    {e.street && <span>{e.street} </span>}
                    {e.stead && <span>участок {e.stead} </span>}
                    {e.house && <span>д. {e.house} </span>}
                    {e.flat && <span>кв. {e.flat} </span>}
                    {e.entrance && <span>под. {e.entrance} </span>}


                    <div>{e.settlement && <span>село {e.settlement} </span>}{e.city && <span>г. {e.city} </span>}{e.region && <span>{e.region}</span>}</div>
                    
                </div>) : <Placeholder data={<FontAwesomeIcon icon={faClipboardList} />} />}

                </div>
            </div>
        </div>
    </Modal>

}

export {ViewAddress,Point,Address}