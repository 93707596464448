
import { useEffect, useState } from 'react'
import { Img, api, Button, ModalMini } from './../Run'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faRubleSign } from '@fortawesome/free-solid-svg-icons'

let Index = ({products,setProducts}) => {

    let [button,setButtom] = useState(false)

    let add = (product) => {

        setButtom(true)

        setProducts(products.map(e => e.id === product.id ? {...e, buttonAdd:true } : e))

        api('POST', '/cart', {index:'add',id:product.id}).then(response => {

            if (response.status) {

                setProducts(products.find(p => p.id === product.id) ? products.map(e => e.id === product.id ? {...e, amount: response.amount, buttonAdd:false } : e) : [...products, {name:product.name,id:product.id, amount: response.amount}])

            }
            
            setButtom(false)
            
        })

    }

    let remove = (product) => {

        setButtom(true)
        
        setProducts(products.map(e => e.id === product.id ? {...e, buttonRemove:true } : e))

        api('POST', '/cart', {index:'remove',id:product.id}).then(response => {

            if (response.status) {

                setProducts(products.find(p => p.id === product.id) ? products.map(e => e.id === product.id ? {...e, amount: response.amount, buttonRemove:false } : e) : [...products, {name:product.name,id:product.id, amount: response.amount}])

            }

            setButtom(false)

        })

    }
    
    return products && products.map((e,key)=> e.amount !== 0 && <div key={key} className='mb-3'>
        
        <b>{key +1}.</b> {e.name} - x{e.price}
        
        <div className='mt-2'>
            <button onClick={() => add(e)} className='btn-buy' disabled={button}><Button title={<><FontAwesomeIcon icon={faPlus} /> 300 <FontAwesomeIcon icon={faRubleSign} /></>} color='btn-load-black' param={e.buttonAdd}/></button>
            {e.amount} 
            <button onClick={() => remove(e)} className='btn-buy' disabled={button} ><Button title={<FontAwesomeIcon icon={faMinus} />} color='btn-load-black' param={e.buttonRemove}/></button>
        </div>
    
    </div>)
    
}

export default Index