import { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from "swiper";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'

let Index = () => {
    
    let [swiperRef, setSwiperRef] = useState(null)
    
    return <div className='cart-pay user-select-none'><div className='cart-pay-box'>
        
        <div className='row mb-3'>
            
            <Swiper className='col-md-12' onSwiper={setSwiperRef} initialSlide={0} slidesPerView={'auto'} navigation={true} modules={[Navigation]} >
                <SwiperSlide className={'bar-item bar-item-active'}><FontAwesomeIcon icon={faCreditCard} /> Оплата картой</SwiperSlide>
            </Swiper>
            
        </div>

    </div></div>
    
}

export default Index