import { Link } from 'react-router-dom'
import { routerModal } from './../Run'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser } from '@fortawesome/free-solid-svg-icons'

import './style.css'
import Logo from './../svg/logo.svg'

import { ViewAddress } from '../Address/General'

let Index = ({root,setRoot,setModal,load,setLoad}) => {

    return <div className='header'>
        <div className='container py-2'>
            <div className='d-flex'>
                <div className='header-logo'>
                    <Link to='/'><img className='img-fluid' src={Logo} /></Link>
                </div>
                <div className='header-address mx-2'>
                    <ViewAddress type='point' open={() => routerModal('point',setModal)} root={root} load={load} />
                </div>
                <div>
                    <Link to='/settings' className='btn btn-primary'><FontAwesomeIcon icon={faCircleUser} /></Link>
                </div>
            </div>
        </div>
    </div>
    
}

export default Index