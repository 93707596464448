import { api, Modal, routerModal, Load, Button } from './../Run'

import Login from '../Login/Index'

import Address from './Address'
import Products from './Products'
import Time from './Time'
import Promocode from './Promocode'
import Pay from './Pay'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark,faCreditCard,faRubleSign } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'

import './style.css'

let Index = ({close,root,setRoot,setModal,products,setProducts}) => {
    
    let [load,setLoad] = useState({cart: true, button:false,address:false})

    let [data,setData] = useState({})

    let [input,setInput] = useState({cardCheckbox:true,promocode:''})

    useEffect(() => {
        
        setLoad(load => ({...load, cart:true}))
    
        api('POST', '/cart', {index:'cart'}).then(response => {
            
            if (response.status) {

                setProducts(response.products)

            }
            
            setLoad(load => ({...load, cart:false}))
        })

    },[root])

    let change = (e) => {
        
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
        
        setInput(input => ({...input, [e.target.name]:value}))

        e.persist()
    }

    let bay = () => {

        setLoad(load => ({...load, button:true}))

        api('POST', '/cart', {index:'buy',cardCheckbox:input.cardCheckbox}).then(response => {

            if (response.status) {

                routerModal(null,setModal)
                routerModal('sber',setModal)

            }
        
            setLoad(load => ({...load, button:false}))

        })
    }
    
    return <Modal close={()=>close(null)}>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 modal-container' onMouseDown={e => {e.stopPropagation()}}>
                    
                    <div className='modal-header'>
                        <button onClick={()=>close(null)} className='btn btn-dark'><FontAwesomeIcon icon={faXmark} /></button>
                    </div>
                    
                    {load.cart ? <Load /> : <div className='mt-3'>


                        <div className='border-bottom'>

                            <Address setModal={setModal} load={load} setLoad={setLoad} data={root.point} />

                        </div>
                        
                        <div className='border-bottom mb-3 pt-3'>

                            <Products products={products} setProducts={setProducts} />

                        </div>

                        {/* <div className='border-bottom mb-3'>

                            <Time load={load} setLoad={setLoad} data={data} />

                        </div> */}
                        
                        <div className='mb-3 border-bottom pb-3'><Login root={root} setRoot={setRoot} /></div>
{/* 
                        <div className='my-3 border-bottom'>

                            <Promocode load={load} setLoad={setLoad} data={data} />
                        
                        </div> */}

                        <Pay />

                        {/* <div className='form-check form-switch mb-3'>
                            <input onChange={change} name='cardCheckbox' className='form-check-input' type='checkbox' id='card' checked={input.cardCheckbox} />
                            <label className='form-check-label' htmlFor='card'>Cохранить карту</label>
                        </div> */}
                        
                        <div className='d-grid gap-2 mb-3'>
                            <button onClick={bay} className='btn btn-primary btn-lg' disabled={load.button}>
                                <Button title={<><FontAwesomeIcon icon={faCreditCard} /> Оплатить {700} <FontAwesomeIcon icon={faRubleSign} /></>} color='btn-lg-white' param={load.button}/>
                            </button>
                        </div>
                        
                    </div>}
                    
                </div>
            </div>
        </div>
    </Modal>
    
}

export default Index