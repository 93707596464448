import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import './style.css'

let Index = () => {
    
    return <div style={{overflow:'hidden'}}>
        <div className='container bar user-select-none'>

            <Swiper initialSlide={3} slidesPerView={'auto'} style={{overflow:'visible'}}>
                <SwiperSlide className='bar-item'>Все</SwiperSlide>
                <SwiperSlide className='bar-item'>Продукты</SwiperSlide>
                <SwiperSlide className='bar-item'>Цветы</SwiperSlide>
                <SwiperSlide className='bar-item'>Цветы</SwiperSlide>
                <SwiperSlide className='bar-item'>Цветы</SwiperSlide>
                <SwiperSlide className='bar-item'>Цветы</SwiperSlide>
                <SwiperSlide className='bar-item'>Цветы</SwiperSlide>
                <SwiperSlide className='bar-item'>Цветы</SwiperSlide>
                <SwiperSlide className='bar-item'>Цветы</SwiperSlide>
                <SwiperSlide className='bar-item'>Цветы</SwiperSlide>
                <SwiperSlide className='bar-item'>Цветы</SwiperSlide>
                <SwiperSlide className='bar-item'>Цветы</SwiperSlide>
            </Swiper>
            
        </div>
    </div>
    
}

export default Index