import Login from '../Login/Index'

let Index = ({root,setRoot}) => {

    return <div className='container'>
        <div className='row'>
            <div className='col-md-4 offset-md-4 mt-3'><Login root={root} setRoot={setRoot} /></div>
        </div>
        <hr />
    </div>
    
}

export default Index