import { useEffect, useRef, useState } from 'react'
import { api } from './../Run'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from "swiper";
import { faList } from '@fortawesome/free-solid-svg-icons';

let Index = ({load,setLoad,data}) => {

    let [time,setTime] = useState(data.time)
    let [timeList,setTimeList] = useState([])

    let [input,setInput] = useState({time:data.cart.time_checkbox})

    let isActive = useRef(true)
    let isActiveInterval = useRef()
    
    let [swiperRef, setSwiperRef] = useState(null)

    let list = () => {

        if (!input.time) {
            
            api('POST', '/cart', {index:'listTime'}).then(response => {
                
                if (isActive.current) {
                    
                    if (response.status) {
                        
                        setTime(response.listTime)
                        clickDate(response.listTime[0])
                    
                    }
            
                }
            
            })
        }

    }

    useEffect(()=>{

        isActiveInterval.current = setInterval(() => {

            
                list()
            
        
        },108000)

        return () => {

            isActive = false
            clearInterval(isActiveInterval.current)

        }

    },[])
    
    let clickDate = (e) => {

        setInput(input => ({...input, month:e.date}))
        
        let temp
        
        if (e.time) {
            
            temp = []
            let i = e.time.f
        
            while (i <= e.time.t) {
                temp.push(i)
                i++
            }
        
        } else {

            temp = false

        }

        setTimeList(timeList => ({...timeList, item: e.item, time:temp}))

    }

    let clickTime = (e) => {

        setLoad(load => ({...load, button:true,time:true}))

        setInput(input => ({...input, clock:e.clock}))

        api('POST', '/cart', {index:'editTime',...input, clock:e}).then(response => {
                
            // if (response.status) {
                
            //     setInput(input => ({...input, ...response.time}))
        
            // }
        
            setLoad(load => ({...load, button:false,time:false}))
    
        })

    }

    let checkbox = (e) => {
        
        setLoad(load => ({...load, button:true,time:true}))
        
        setInput(input => ({...input, [e.target.name]:e.target.checked}))
        
        if (isActive.current) {
            
            api('POST', '/cart', {index:'editTime',...input, [e.target.name]:e.target.checked}).then(response => {
                
                // if (response.status) {
                    
                //     setInput(input => ({...input, ...response.time}))
            
                // }
            
                setLoad(load => ({...load, button:false,time:false}))
        
            })

        }

        e.persist()
    }
    
    return <div>
        
        <div className='form-check form-switch mb-3'>
            
            <input onChange={checkbox} name='time' disabled={load.time} className='form-check-input' type='checkbox' id='time' checked={input.time} />
            <label className='form-check-label' htmlFor='time'>Доставка в ближайшее время</label>
        
        </div>
        
        {!input.time && <div className='mb-3'>
            
            <div className='cart-time user-select-none'>
                
                <div className='cart-time-box'>
                    
                    <div className='row mb-3'>
                        
                        <Swiper className='col-md-12' onSwiper={setSwiperRef} initialSlide={0} slidesPerView={'auto'} navigation={true} modules={[Navigation]} >
                            
                            {time.map((e,key)=><SwiperSlide key={key} onClick={()=> clickDate(e)} className={'bar-item ' + (e.item === timeList.item && 'bar-item-active')}>{e.item}</SwiperSlide>)}
                            
                        </Swiper>
                        
                    </div>
                    
                    {timeList.time ? <div className='row'>
                        
                        <Swiper className='col-md-12' onSwiper={setSwiperRef} initialSlide={0} slidesPerView={'auto'} navigation={true} modules={[Navigation]} >
                            
                            {timeList.time.map((e,key) => <SwiperSlide key={key} onClick={()=>clickTime(e)} className={'bar-item'}>{String(e).padStart(2,'0')}:00</SwiperSlide>)}
                            
                        </Swiper>
                    
                    </div> : <div className='item-time-close'>Не принимает заказы</div>}
                    
                </div>
            
            </div>
            
        </div>}
        
    </div>
    
}

export default Index