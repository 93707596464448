
// import { useEffect } from 'react'
import { Img, api } from './../Run'

import { Link } from 'react-router-dom'

import Bar from './Bar'

import './style.css'

let Index = ({load,shop}) => {


    if (load) {
        return <div>

            <div className='py-2 mb-2'><Bar load={load} /></div>
            
            {shop.open.length ? <div className='container'>
                
                <h2 className='title mb-2 ms-2'>Открытые рестораны</h2>
                <div className='row'>
                    {shop.open.map((e,key) => <div key={key} className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        
                        <Link to={'/shop@'+e.id} className='decoration'>
                            <div className='shop-box'>
                                <div><Img className={false ? 'img-bg close' : 'img-bg'} src={'//files.tin.bar/'} /></div>
                                <div className='title'>{e.name}</div>
                            </div>
                        </Link>
                        
                    </div>)}
                </div>
            
            </div> : ''}
            
            {shop.close.length ? <div className='container'>
                <h2 className='title mb-2 ms-2'>Закрытые рестораны</h2>
                <div className='row'>
                    {shop.close.map((e,key) => <div key={key} className='col-sm-6 col-md-6 col-lg-4 col-xl-3'>
                        
                        <Link to={'/shop@'+e.id} className='decoration'>
                            <div className='shop-box'>
                                <div><Img className={false ? 'img-bg close' : 'img-bg'} src={'//files.tin.bar/'} /></div>
                                <div className='title'>{e.name}</div>
                            </div>
                        </Link>
                    
                    </div>)}
                </div>
            </div> : ''}
        
        </div>
    } else {
        return <div className='container'>load</div>
    }
    
}

export default Index